import './App.css';
import { WebChatContainer } from '@ibm-watson/assistant-web-chat-react';
import ResponseWithLLM from './components/ResponseWithLLM';
import ResponseForFindDoctorsAccordion from './components/ResponseForFindDoctorsAccordion';
import ResponseForFindTrials from './components/ResponseForFindTrials';

function renderCustomResponse(event, webChatInstance) {
  const { message } = event.data;
  event.data.fullWidth = true;
  if (message.user_defined && message.user_defined.response_component === 'General_LLM_Response') {
    // General_LLM_Response
    return <ResponseWithLLM llmRes={message.user_defined.llmRes} webChatInstance={webChatInstance} userInput={message.user_defined.userInput}/>;
  } else if (message.user_defined && message.user_defined.response_component === 'Find_Doctor_LLM_Response') {
    // Find_Doctor_LLM_Response
    return <ResponseForFindDoctorsAccordion llmRes={message.user_defined.llmRes} webChatInstance={webChatInstance} userInput={message.user_defined.userInput} />
  } else if (message.user_defined && message.user_defined.response_component === 'Find_Trials_LLM_Response') {
    // console.log(message.user_defined);

    // Find_Trials_LLM_Response
    return <ResponseForFindTrials llmRes={message.user_defined.llmRes} webChatInstance={webChatInstance} userInput={message.user_defined.userInput} />
  }

  return null;
}

function App() {

  const integrationID = process.env.REACT_APP_IBM_INTEGRATION_ID;
  const region = process.env.REACT_APP_IBM_REGION;
  const serviceInstanceID = process.env.REACT_APP_IBM_SERVICE_INSTANCE_ID;

  return (
    <WebChatContainer
      config={{
        showRestartButton: true,        
        integrationID,
        region,
        serviceInstanceID,
        // only for full page
        openChatByDefault: true,
        hideCloseButton: true,
      }}
      renderCustomResponse={renderCustomResponse}
    />
  );
}

export default App;
