import { useEffect } from "react";
import LinkChip from "./basic/LinkChip";

function ResponseWithLLM({ llmRes, webChatInstance, userInput }) {

    console.log(llmRes);
    

    useEffect(() => {
        if (llmRes === 'TIME_OUT') {
            const triggered = sessionStorage.getItem(userInput);
            if (!triggered) {
                sessionStorage.setItem(userInput, true);
                // console.log('wait 31s ...');
                setTimeout(function () {
                    webChatInstance.send({ input: { text: userInput } });
                }, 31000);
            }
        }
    }, [llmRes, userInput, webChatInstance]);

    return <>
        {llmRes.hasAnswer &&
            <h5 style={{ marginTop: 8, marginBottom: 8 }}>Learn more:
                {
                    llmRes.refs.map((ref, index) => <LinkChip url={ref.url} index={index} key={index} />)
                }
            </h5>}
    </>
}

export default ResponseWithLLM;